
//Color palette
$primary-color: #cfb278;
$secondary-color: #ddbb77;

//Z-index
$UI : 1000; 
$Content : 2000; 

body {
    font-family: Urbanist;
    background-color: black;
    margin: 0;
    padding: 0;
    overflow: hidden;
} 

.credits_wrapper span {
    color: $secondary-color; 
    font-family: 'Book Antiqua';
    letter-spacing: 4px;
    font-size: 10px; 
}

.credits_wrapper {
    line-height: 1.2em;
    font-family: Gotham;
}


#track_lyrics {
    font-family: Book Antiqua;
    scrollbar-width: normal;
    scrollbar-color: red green;
    padding-right: 1.5em;
}

#track_lyrics::-webkit-scrollbar{
    width: 3px;
}

#track_lyrics::-webkit-scrollbar-thumb{
    background-color: $primary-color;
}

#track_lyrics::-webkit-scrollbar-track{
    background-color: #534220;
}

#loadAssets_btn img{
    width: 35vw;
}

#instructions img{
    width: 10vw;
}

#landscapeRequest {
    color: $primary-color;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: black; 
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    text-align: center;
}

#fullscreenRequest {
    color: $primary-color;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: black; 
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    text-align: center;
}

.generic_window {
    color: $primary-color;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: black; 
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
    text-align: center;
} 

.credits_ {
    overflow-y: auto;
}
 

.generic_window p {
    font-size: 13px;
} 

.generic_grid {
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 6vw;
}

.gallery {
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center; 
    position: absolute;
    background-color: black;
} 

.gallery div { 
    width: 80%;
}

#gallery-close { 
    position:absolute;
    color: $primary-color;
    top: 5%;
    left: 3%;
    cursor: pointer;
}

.gallery button {
    border: none;
    background: none;
    color: $primary-color;
    font-size: 24px; 
    width: 10%;
}

.gallery img {
    width: 70%;  
    display: block;
    margin: 0 auto;
}
 
.content {
    background-color: black;
    color: $primary-color;
    width: 100%;
    height: 100%;
    position: absolute;
}

.track_story {
    display: flex;
    flex-direction: column;
    align-items:center; 
    justify-content: center;
    height: 100%;
}

.standard_btn {
    background-color: black;
    color: $primary-color;
    padding: 1em 3em 1em 3em;
    border: 1px solid $primary-color;
    font-family: Urbanist;
    transition: 0.15s;
}

.standard_btn:hover {
    background-color: black;
    background: $primary-color; 
    color: black;
}

#track_story_text {
    width: 80%;
    text-align: left;
    margin-bottom: 2em;
}

.track_player {     
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: center;
    width: 70%;
    margin: 0 auto;
    height: 100%; 
 }

#track_id {
    font-family: Gotham;
    color: white;
}
#track_name {
    font-family: Gotham; 
}

#track_info {
    display: flex;
    font-size: 30px;
}

#track_icon_img{
    width: 80px;
}

#track_info {
    margin-bottom: 1em;
}

.close_btn button
{
    color: $primary-color;
    font-size: 25px;
    background: none;
    border: 0;
}

.player_ui {
    display: flex; align-items: center;
    margin-bottom: 3em;
}

.player_ui i {
    opacity: 0.5; 
    transition: .3s;  
    cursor: pointer;
}

.player_ui i:hover {
    opacity: 1; 
}
 

.fullpage { 
    display: flex;
}

.ui-top {
    position: absolute;
    color: $primary-color; 
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 1em;  
}


.landscape-alert {
    color: white;
    position: absolute;
    background-color: black;
    width: 100%;
    height: 100%;
}

.links { 
    font-size: 20px;
    margin-left: 1.5em;
}

.links * {
    margin-right: 1em;
}

.ui-bottom i {
    margin-left: 1em;
    margin-right: 1em;
    font-size: 20px;
}

i {
    cursor: pointer;
}

#help {
    cursor: pointer;
}

a:visited {
    text-decoration: none;
    color: $primary-color;
}
a {
    text-decoration: none;
    color: $primary-color;
}

.ui-top-right { 
    right: 100%;
    margin-right: 1.5em;
}

.ui-top-right i {
    font-size: 20px;
    margin-right: 0.5em;
}

.ui-bottom {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: $primary-color;
    margin-bottom: 1.2em;
}

.ui-bottom i {
    font-size: 20px;
}

#progress {
    display: flex;
    margin-top: 0.2em;
}

.progressWrapper {
    text-align: center;
}

.progress-line{
    width: 100%; 
    height: 1em; 
    border-bottom: 1px solid #cfb278;
}

#lang {
    position: absolute;
    color: $primary-color;
    left: 2em;
    top: 2em;
    font-size: 15px;
    border: 1px solid $primary-color;
    padding: 1em;
}

#lang span {
    cursor: pointer;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Book.eot');
    src: local('Gotham-Book'),
        url('Gotham-Book.eot?#iefix') format('embedded-opentype'),
        url('Gotham-Book.woff2') format('woff2'),
        url('Gotham-Book.woff') format('woff'),
        url('Gotham-Book.ttf') format('truetype'),
        url('Gotham-Book.svg#Gotham-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gotham';
    src: url('Gotham-Bold.eot');
    src: local('Gotham-Bold'),
        url('Gotham-Bold.eot?#iefix') format('embedded-opentype'),
        url('Gotham-Bold.woff2') format('woff2'),
        url('Gotham-Bold.woff') format('woff'),
        url('Gotham-Bold.ttf') format('truetype'),
        url('Gotham-Bold.svg#Gotham-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Book Antiqua';
    src: url('BookAntiqua-BoldItalic.eot');
    src: local('Book Antiqua Bold Italic'), local('BookAntiqua-BoldItalic'),
        url('BookAntiqua-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('BookAntiqua-BoldItalic.woff2') format('woff2'),
        url('BookAntiqua-BoldItalic.woff') format('woff'),
        url('BookAntiqua-BoldItalic.ttf') format('truetype'),
        url('BookAntiqua-BoldItalic.svg#BookAntiqua-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Book Antiqua';
    src: url('BookAntiqua-Bold.eot');
    src: local('Book Antiqua Bold'), local('BookAntiqua-Bold'),
        url('BookAntiqua-Bold.eot?#iefix') format('embedded-opentype'),
        url('BookAntiqua-Bold.woff2') format('woff2'),
        url('BookAntiqua-Bold.woff') format('woff'),
        url('BookAntiqua-Bold.ttf') format('truetype'),
        url('BookAntiqua-Bold.svg#BookAntiqua-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Book Antiqua';
    src: url('BookAntiqua-Italic.eot');
    src: local('Book Antiqua Italic'), local('BookAntiqua-Italic'),
        url('BookAntiqua-Italic.eot?#iefix') format('embedded-opentype'),
        url('BookAntiqua-Italic.woff2') format('woff2'),
        url('BookAntiqua-Italic.woff') format('woff'),
        url('BookAntiqua-Italic.ttf') format('truetype'),
        url('BookAntiqua-Italic.svg#BookAntiqua-Italic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Book Antiqua';
    src: url('BookAntiqua.eot');
    src: local('Book Antiqua'), local('BookAntiqua'),
        url('BookAntiqua.eot?#iefix') format('embedded-opentype'),
        url('BookAntiqua.woff2') format('woff2'),
        url('BookAntiqua.woff') format('woff'),
        url('BookAntiqua.ttf') format('truetype'),
        url('BookAntiqua.svg#BookAntiqua') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@media screen and (max-width: 992px) {
    #track_story_text {
      font-size: 12px;
    }
    #track_lyrics {
        font-size: 13px;
    }
}